import './App.css';
import VideoPlayer from './views/VideoPlayer';

function App() {
  return (
    <div className="app">
      <VideoPlayer/>
    </div>
  );
}

export default App;
