import React, { useEffect, useRef, useState } from 'react';
import playIcon from "../assets/icons/play-icon.svg";
import stopIcon from "../assets/icons/pause-icon.svg";
import assets from "../config.js";

const VideoPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currTime, setCurrTime] = useState({ min: "", sec: "" });
    const [seconds, setSeconds] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef();

    const startSound = () => {
        audioRef.current.play();
        setIsPlaying(true);
    };

    const pauseSound = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    };

    const handleRangeChange = (e) => {
        audioRef.current.currentTime = parseFloat(e.target.value);
        setSeconds(parseFloat(e.target.value));
    };

    useEffect(() => {
        const updateCurrentTime = () => {
            setSeconds(audioRef.current.currentTime);
            const min = Math.floor(audioRef.current.currentTime / 60);
            const sec = Math.floor(audioRef.current.currentTime % 60);
            setCurrTime({ min, sec });
        };

        const updateDuration = () => {
            setDuration(audioRef.current.duration);
        };

        audioRef.current.addEventListener('timeupdate', updateCurrentTime);
        audioRef.current.addEventListener('durationchange', updateDuration);

        return () => {
            audioRef.current.removeEventListener('timeupdate', updateCurrentTime);
            audioRef.current.removeEventListener('durationchange', updateDuration);
        };
    }, []);

    useEffect(() => {
        const handleSoundEnd = () => {
            setIsPlaying(false);
            setSeconds(0);
            setCurrTime({ min: 0, sec: 0 });
        };

        audioRef.current.addEventListener('ended', handleSoundEnd);

        return () => {
            audioRef.current.removeEventListener('ended', handleSoundEnd);
        };
    }, []);

    return (
        <>
            <div className="background1"></div>
            <div className="background2"></div>
            <div className="sound-controls">
                <>
                    <div>
                        {!isPlaying ? (
                            <img
                                src={playIcon}
                                className="soundicon"
                                onClick={startSound}
                                alt='play-icon'
                            />
                        ) : (
                            <img
                                src={stopIcon}
                                className="soundicon"
                                onClick={pauseSound}
                                alt='stop-icon'
                            />
                        )}
                    </div>
                    <div className="timeline-outer">
                        <input
                            type="range"
                            min="0"
                            max={duration}
                            value={seconds}
                            className="timeline"
                            onChange={handleRangeChange}
                        />
                    </div>
                    <audio
                        controls
                        src={assets[1].sound}
                        style={{ display: "none" }}
                        ref={audioRef}
                    >
                    </audio>
                </>
            </div>
        </>
    );
};

export default VideoPlayer;
